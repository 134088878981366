<template>
    <SearchList />
</template>

<script>
import {
    mapActions
} from 'vuex'

import SearchList from '../components/SearchList.vue'

export default {
    components: {
        SearchList
    },
    data: () => ({
    }),
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
        //this.getUserInfo(this)
    },
}
</script>

